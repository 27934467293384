import React from "react";

export function InfoReceipt({ name, value, minWidthName = true }) {
  return (
    <div className="flex">
      <div className={`${minWidthName ? "w-36" : ""} pr-2 font-semibold`}>
        {name}
      </div>
      <div className="">{value}</div>
    </div>
  );
}

export function ParaReceipt({ name, value }) {
  return (
    <div className="flex flex-col">
      <div className="font-semibold">{name}</div>
      <div className="">{value}</div>
    </div>
  );
}
