import React from 'react';

export default function ButtonNavigation({ onClick, disabled, children }) {
  return (
    <button
      className={
        `${disabled ? 'opacity-50' : ''}` +
        ' cursor-default p-2 transition hover:cursor-default'
      }
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
}
