import React from 'react';
import TableCustom from '../../../components/TableCustom';
import { getUsers, deleteUser } from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import LinkCustom from '../../../components/LinkCustom';
import ButtonCustom from '../../../components/ButtonCustom';
import Loading from '../../../components/Loading';
import { ContainerButtonsTable } from '../../../components/OptionsButtons';

export default function UsersList() {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Nombre',
      accessor: 'name',
    },
    {
      Header: 'Apellido',
      accessor: 'last_name',
    },
    {
      Header: 'Email',
      accessor: 'email',
    },
    {
      Header: 'Rol',
      accessor: 'role',
    },
    {
      Header: 'Opciones',
      accessor: (props) => (
        <ContainerButtonsTable>
          <LinkCustom
            text="Editar"
            to={`/users/${props.id}`}
            className="text-xs"
          />
          {props.id !== '1' && (
            <ButtonCustom
              text="Eliminar"
              onClick={() => handleDelete(props.id)}
              className="text-xs"
              color="danger"
            />
          )}
        </ContainerButtonsTable>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    const resp = await getUsers();
    if (resp.success) {
      const dataProcessed = resp.data.map((item) => {
        return {
          ...item,
          role: item.roles[0] ? item.roles[0].name : '',
        };
      });

      setData(dataProcessed);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Una vez eliminado, no podrás recuperar este registro!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then(async (result) => {
      if (result.value) {
        const resp = await deleteUser(id);
        if (resp.success) {
          Swal.fire('Eliminado!', resp.message, 'success');
          handleGet();
        }
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader to={`/users/new`} title="Listado de usuarios" />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
