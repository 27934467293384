import React from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import ButtonCustom from '../../../components/ButtonCustom';
import { InfoReceipt, ParaReceipt } from '../../../components/InfoReceipt';
import { getService } from '../../../services/ws';
import Loading from '../../../components/Loading';

import { lstTypeEquipments, lstBrands } from '../../../utils/LstData';
import { firm, direction, Description } from '../../../utils/infoData';

export default function ServiceReceipt() {
  const { id } = useParams();
  const componentRef = React.useRef();

  const [current, setCurrent] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getDataCurrent(id);
  }, [id]);

  const getDataCurrent = async (idCurrent) => {
    if (idCurrent) {
      const resp = await getService(idCurrent);

      if (resp.success) {
        const { data } = resp;
        const dataProcessed = {
          ...data,
          income_name:
            (data.income.name || '') + ' ' + (data.income.last_name || ''),
          client_name:
            (data.client.name || '') + ' ' + (data.client.last_name || ''),
          created_at: getDateTimeLocal(data.created_at),
          equipment_brand: lstBrands.find(
            (brand) => String(brand.id) === String(data.equipment.brand)
          )?.name,
          equipment_type: lstTypeEquipments.find(
            (type) => String(type.id) === String(data.equipment.type)
          )?.name,
        };
        setCurrent(dataProcessed);
      }
    }
    setIsLoading(false);
  };

  const getDateTimeLocal = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date();
    const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year =
      date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
    const seconds =
      date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

    return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="">
      <ReactToPrint
        trigger={() => <ButtonCustom text="Imprimir" />}
        content={() => componentRef.current}
      />
      <div className="overflow-scroll">
        <div
          ref={componentRef}
          className="relative w-[1120px] border p-8 font-mono"
        >
          <div className="absolute top-0 right-0">
            <div className="m-6 flex items-center border-2 border-slate-500 py-2 px-4">
              <label className="mr-3 text-xl font-semibold underline">
                Orden:{' '}
              </label>
              <label className="text-xl font-semibold">{current.id}</label>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-2xl font-semibold underline">
              GSTECNICO-ORDEN DE REPARACIÓN
            </p>
            <p className="">{direction}</p>
          </div>

          <div>
            <div className="mt-3 flex">
              <div className="w-1/2">
                <InfoReceipt
                  name="Fecha de Ingreso:"
                  value={current.created_at}
                  minWidthName={false}
                />
              </div>
              <div className="w-1/2">
                <InfoReceipt
                  name="Ingresado por:"
                  value={current.income_name}
                  minWidthName={false}
                />
              </div>
            </div>

            <div className="mt-3 flex">
              <div className="w-1/2">
                <InfoReceipt name="Nombres:" value={current.client_name} />
                <InfoReceipt
                  name="Dirección:"
                  value={current.client?.address}
                />
              </div>
              <div className="w-1/2">
                <InfoReceipt name="Celular:" value={current.client?.phone} />
                <InfoReceipt name="Correo" value={current.client?.email} />
              </div>
            </div>

            <div className="mt-3 flex">
              <div className="w-1/2">
                <InfoReceipt
                  name="Tipo de Equipo:"
                  value={current.equipment_type}
                />
                <InfoReceipt name="Modelo:" value={current.equipment?.model} />
              </div>
              <div className="w-1/2">
                <InfoReceipt name="Marca:" value={current.equipment_brand} />
                <InfoReceipt name="Serie:" value={current.equipment?.serie} />
              </div>
            </div>

            <div className="mt-3">
              <ParaReceipt
                name="ACCESORIOS Y ADICIONALES ENTREGADOS:"
                value={current.accessories}
              />
            </div>
            <div className="mt-3">
              <ParaReceipt
                name="FALLA REPORTADA / TRABAJO SOLICITADO:"
                value={current.failure}
              />
            </div>

            <div className="mt-3">
              <InfoReceipt
                name="POSIBLE FECHA DE ENTREGA:"
                value={current.delivery_date}
                minWidthName={false}
              />
            </div>

            <div className="mt-11 mb-3 flex">
              <div className="w-1/2">
                <div className="flex items-center justify-center">
                  <div className="pr-6 font-semibold underline">{firm}</div>
                  <div className="">______________________________</div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-center">
                  <div className="pr-6 font-semibold underline">CLIENTE: </div>
                  <div className="">______________________________</div>
                </div>
              </div>
            </div>

            <Description />
          </div>
        </div>
      </div>
    </div>
  );
}
