export const validateSpecialCharacters = (value) => {
  const regex = /^[a-zA-Z\s]+$/;
  return regex.test(value);
};

export const validateCI = (cedula) => {
  if (cedula.length === 10) {
    let digito_region = cedula.substring(0, 2);

    if (digito_region >= 1 && digito_region <= 24) {
      let ultimo_digito = cedula.substring(9, 10);
      let pares =
        parseInt(cedula.substring(1, 2)) +
        parseInt(cedula.substring(3, 4)) +
        parseInt(cedula.substring(5, 6)) +
        parseInt(cedula.substring(7, 8));

      let numero1 = cedula.substring(0, 1);
      numero1 = numero1 * 2;
      if (numero1 > 9) {
        numero1 = numero1 - 9;
      }

      let numero3 = cedula.substring(2, 3);
      numero3 = numero3 * 2;
      if (numero3 > 9) {
        numero3 = numero3 - 9;
      }

      let numero5 = cedula.substring(4, 5);
      numero5 = numero5 * 2;
      if (numero5 > 9) {
        numero5 = numero5 - 9;
      }

      let numero7 = cedula.substring(6, 7);
      numero7 = numero7 * 2;
      if (numero7 > 9) {
        numero7 = numero7 - 9;
      }

      let numero9 = cedula.substring(8, 9);
      numero9 = numero9 * 2;
      if (numero9 > 9) {
        numero9 = numero9 - 9;
      }

      let impares = numero1 + numero3 + numero5 + numero7 + numero9;

      let suma_total = pares + impares;

      let primer_digito_suma = String(suma_total).substring(0, 1);

      let decena = (parseInt(primer_digito_suma) + 1) * 10;

      let digito_validador = decena - suma_total;

      if (digito_validador === 10) {
        digito_validador = 0;
      }

      if (digito_validador === parseInt(ultimo_digito)) {
        return {
          success: true,
        };
      } else {
        return {
          success: false,
          message: "La cédula: " + cedula + " es incorrecta",
        };
      }
    } else {
      return {
        success: false,
        message: "La cédula no pertenece a ninguna region",
      };
    }
  } else {
    return {
      success: false,
      message: "La cédula tiene menos de 10 Dígitos",
    };
  }
};
