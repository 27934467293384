import React from 'react';

import TableCustom from '../../../components/TableCustom';
import Loading from '../../../components/Loading';
import ContentHeader from '../../../components/ContentHeader';
import { ButtonCustomRef } from '../../../components/ButtonCustomRef';
import {
  SelectCustom,
  InputDate,
  InputCustom,
} from '../../../components/FormElements';
import { getDate } from '../../../utils/getInfo';
import { getUsers, reportServiceResponsibleByDate } from '../../../services/ws';
import Swal from 'sweetalert2';
import { lstTaxes } from '../../../utils/LstData';
import ReactToPrint from 'react-to-print';
import ButtonCustom from '../../../components/ButtonCustom';
import TableSimple from '../../../components/TableSimple';

export default function ReportServices() {
  const columns = [
    {
      Header: '# Orden',
      accessor: 'id',
    },
    /* {
      Header: 'Fecha de ingeso',
      accessor: (props) => getDate(props.created_at),
    }, */
    {
      Header: 'Serie de equipo',
      accessor: 'equipment.serie',
    },
    {
      Header: 'Cliente',
      accessor: (props) => getName(props.client),
    },
    {
      Header: 'Responsable',
      accessor: (props) => getName(props.responsible),
    },
    {
      Header: 'Materiales',
      accessor: (props) => '$' + props.materials_price,
    },
    {
      Header: 'Mano de obra',
      accessor: (props) => '$' + props.price,
    },
    {
      Header: 'Total (IVA)',
      accessor: (props) => '$' + props.total,
    },
  ];

  const btn = React.useRef();
  const table = React.useRef();
  const tableSimple = React.useRef();
  const componentRef = React.useRef();

  const [current, setCurrent] = React.useState({
    responsible_id: '0',
    start_date: getDate(),
    end_date: getDate(),
  });
  const [lstUsers, setLstUsers] = React.useState([]);
  const [data, setData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    handleGetUsers();
  }, []);

  const handleGetUsers = async () => {
    const resp = await getUsers();
    const allUsers = {
      id: 0,
      name: 'Todos',
    };

    if (resp.success) {
      const dataFilter = resp.data.filter((item) => item.roles[0]?.id !== 3);
      setLstUsers([allUsers, ...dataFilter]);
    } else {
      setLstUsers([allUsers]);
    }
  };

  const handleChange = (e) => {
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    btn && btn.current.setAttribute('disabled', true);
    setIsLoading(true);

    try {
      const resp = await reportServiceResponsibleByDate(current);
      if (resp.success) {
        const dataProcessed = resp.data.map((item) => ({
          ...item,
          materials_price: handleTotalMaterialService(item),
          total: handleTotalService(item),
        }));
        setData(dataProcessed);
        /* setData(
          [].concat(
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed,
            dataProcessed
          )
        ); */
      }

      if (resp.error === 'BE001') throw new Error(resp.message);
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }

    setIsLoading(false);
    btn && btn.current.removeAttribute('disabled');
  };

  const handleTotalMaterialService = (item) => {
    let total = 0;
    item.materials.forEach((item) => {
      total += parseFloat(item.price) * parseFloat(item.pivot.quantity);
    });
    return total.toFixed(2);
  };

  const handleTotalService = (service) => {
    const materials_price = service.materials.reduce(
      (acc, item) => acc + item.price * item.pivot.quantity,
      0
    );

    const idTax = service.tax ? service.tax : 1;
    const taxes =
      lstTaxes.find((tax) => String(tax.id) === String(idTax))?.value / 100;

    const total = (
      (parseFloat(service.price) + parseFloat(materials_price)) *
      (1 + taxes)
    ).toFixed(2);

    return total;
  };

  const getName = (infoUser) => {
    if (!infoUser) {
      return '';
    }
    let nameText = '';
    if (infoUser.name) {
      nameText += infoUser.name;
    }
    if (infoUser.last_name) {
      nameText += ` ${infoUser.last_name}`;
    }
    return nameText;
  };

  const handleTotalMaterial = () => {
    let total = 0;
    data.forEach((item) => {
      total += parseFloat(item.materials_price);
    });
    return total.toFixed(2);
  };

  const handleTotalPrice = () => {
    let total = 0;
    data.forEach((item) => {
      total += parseFloat(item.price);
    });
    return total.toFixed(2);
  };

  const handleTotal = () => {
    let total = 0;
    data.forEach((item) => {
      total += parseFloat(item.total);
    });
    return total.toFixed(2);
  };

  return (
    <div>
      <ContentHeader /* title="Reporte de servicios" */>
        <ReactToPrint
          trigger={() => <ButtonCustom text="Imprimir" />}
          content={() => componentRef.current}
          pageStyle={() => {
            return `
              @page {
                size: A4;
                margin: 0;
              }
              @media print {
                html, body {
                  width: 210mm;
                  height: 297mm;
                }
              }
            `;
          }}
          onBeforeGetContent={() => {
            table.current.style.display = 'none';
            tableSimple.current.style.display = 'block';
          }}
          onAfterPrint={() => {
            table.current.style.display = 'block';
            tableSimple.current.style.display = 'none';
          }}
        />
        <ButtonCustomRef
          text="Generar Reporte"
          ref={btn}
          form="form-current"
          type="submit"
        />
      </ContentHeader>

      <div ref={componentRef} className="p-4">
        <h2 className="my-6 text-center text-xl font-semibold">
          Reporte de servicios
        </h2>
        <form
          id="form-current"
          onSubmit={handleSubmit}
          className="mt-2 flex rounded-lg border p-4"
        >
          <SelectCustom
            text="Usuario"
            name="responsible_id"
            value={current.responsible_id}
            n="3"
            onChange={handleChange}
            lst={lstUsers}
            required
          />

          <InputDate
            text="Fecha Inicio"
            name="start_date"
            value={current.start_date}
            n="3"
            onChange={handleChange}
            required
          />

          <InputDate
            text="Fecha Fin"
            name="end_date"
            value={current.end_date}
            n="3"
            onChange={handleChange}
            required
          />
        </form>

        {isLoading && <Loading />}

        {!isLoading && (
          <>
            <div ref={table}>
              <TableCustom columns={columns} data={data} search={false} />
            </div>
            <div ref={tableSimple} style={{ display: 'none' }}>
              <TableSimple columns={columns} data={data} />
            </div>
          </>
        )}

        <div className="mt-5 flex flex-row items-end justify-end">
          <InputCustom
            text="Total materiales e insumos"
            value={'$' + handleTotalMaterial()}
            n="3"
            disabled
          />
          <InputCustom
            text="Total de mano de obra"
            value={'$' + handleTotalPrice()}
            n="3"
            disabled
          />
          <InputCustom
            text="Total (IVA incluido)"
            value={'$' + handleTotal()}
            n="3"
            disabled
          />
        </div>
      </div>
    </div>
  );
}
