import React from "react";
import Welcome from "./Welcome";
import WelcomeClient from "./WelcomeClient";

import { AppContext } from "../../context/AppContext";

export default function Home() {
  const [appState] = React.useContext(AppContext);
  const idRole = appState.user.roles[0]?.id;

  if (idRole === 1 || idRole === 2) {
    return <Welcome />;
  }

  if (idRole === 3) {
    return <WelcomeClient />;
  }

  return <div>No tienes permisos</div>;
}
