import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getRoles, getUser, postUser, putUser } from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import { ButtonCustomRef } from '../../../components/ButtonCustomRef';
import {
  ContainerInput,
  InputCustom,
  SelectCustom,
} from '../../../components/FormElements';
import { AppContext } from '../../../context/AppContext';
import Loading from '../../../components/Loading';

import {
  validateSpecialCharacters,
  validateCI,
} from '../../../utils/validations';

export default function UserForm() {
  const [appState, setAppState] = React.useContext(AppContext);
  const idRole = appState.user.roles[0]?.id;

  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [user, setUser] = React.useState({});
  const [lstRole, setLstRole] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getDataRoles();
  }, []);

  React.useEffect(() => {
    getDataUser(id);
  }, [id]);

  const getDataUser = async (idUser) => {
    if (idUser) {
      const resp = await getUser(idUser);
      if (resp.success) {
        setUser(resp.data);
      }
    }
    setIsLoading(false);
  };

  const getDataRoles = async () => {
    const resp = await getRoles();
    if (resp.success) {
      setLstRole(resp.data);
    }
  };

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btn && btn.current.setAttribute('disabled', true);

    if (!validateSpecialCharacters(user.name)) {
      Swal.fire({
        title: 'Nombre incorrecto',
        text: 'Solo se permiten letras, numeros y guion bajo',
        icon: 'warning',
      });
      btn && btn.current.removeAttribute('disabled');
      return;
    }

    if (!validateSpecialCharacters(user.last_name)) {
      Swal.fire({
        title: 'Apellido incorrecto',
        text: 'Solo se permiten letras',
        icon: 'warning',
      });
      btn && btn.current.removeAttribute('disabled');
      return;
    }

    const isValidateCI = validateCI(user.ci);
    if (!isValidateCI.success) {
      Swal.fire({
        title: 'Cedula Incorrecta',
        text: isValidateCI.message,
        icon: 'warning',
      });
      btn && btn.current.removeAttribute('disabled');
      return;
    }

    try {
      if (id) {
        const resp = await putUser(user);
        if (resp.success) {
          Swal.fire({
            icon: 'success',
            title: 'Se ha actualizado correctamente',
            text: '',
          });

          if (String(appState.user.id) === String(resp.data.id)) {
            setAppState.setUser(resp.data);
          }

          navigate('/users');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Validación',
            text: resp.message,
          });
        }
      } else {
        const resp = await postUser(user);
        if (resp.success) {
          Swal.fire({
            icon: 'success',
            title: 'Se ha registrado correctamente',
            text: '',
          });
          navigate('/users');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Validación',
            text: resp.message,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }

    if (btn?.current) {
      btn.current.removeAttribute('disabled');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      <ContentHeader title={id ? 'Editar Usuario' : 'Registrar Usuario'}>
        <ButtonCustomRef
          text="Guardar"
          ref={btn}
          form="form-user"
          type="submit"
        />
      </ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-user"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <ContainerInput>
            <InputCustom
              text="Nombre"
              name="name"
              value={user.name}
              n="2"
              onChange={handleChange}
              required
            />
            <InputCustom
              text="Apellido"
              name="last_name"
              value={user.last_name}
              n="2"
              onChange={handleChange}
              required
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Cédula"
              name="ci"
              value={user.ci}
              n="2"
              onChange={handleChange}
              minLength="10"
              maxLength="10"
              required
            />
            <InputCustom
              text="Teléfono"
              name="phone"
              value={user.phone}
              n="2"
              onChange={handleChange}
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Email"
              name="email"
              value={user.email}
              type="email"
              onChange={handleChange}
              autoComplete="off"
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Dirección"
              name="address"
              value={user.address}
              onChange={handleChange}
            />
          </ContainerInput>
          {idRole === 1 && (
            <ContainerInput>
              <SelectCustom
                text="Rol"
                name="role_id"
                value={user.role_id}
                onChange={handleChange}
                lst={lstRole}
                required
              />
            </ContainerInput>
          )}

          <ContainerInput>
            <InputCustom
              text="Contraseña"
              name="password"
              value={user.password}
              type="password"
              onChange={handleChange}
              minLength="8"
              autoComplete="new-password"
            />
          </ContainerInput>
        </form>
      </div>
    </div>
  );
}
