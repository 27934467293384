import * as React from 'react';
import { restoreLogin } from '../services/ws';

export const AppContext = React.createContext({});

export const AppContextProvider = ({ children }) => {
  const [appState, dispatch] = React.useReducer(
    (state, action) => {
      switch (action.type) {
        case 'restore_login':
          return {
            ...state,
            user: action.user,
            isLoading: false,
          };
        case 'login':
          return {
            ...state,
            user: action.user,
            isLoading: false,
          };
        case 'logout':
          return {
            ...state,
            user: null,
            isLoading: false,
          };
        case 'set_loading':
          return {
            ...state,
            isLoading: action.isLoading,
          };
        case 'set_user':
          return {
            ...state,
            user: action.user,
          };
        case 'set_notifications':
          return {
            ...state,
            notifications: action.notifications,
          };

        default:
          return state;
      }
    },
    {
      user: null,
      notifications: 0,
      isLoading: true,
    }
  );

  React.useEffect(() => {
    try {
      const user = JSON.parse(localStorage.getItem('user'));
      const token = localStorage.getItem('token');

      const handleRestoreLogin = async () => {
        const resp = await restoreLogin(user.id, token);

        if (resp.success) {
          localStorage.setItem('user', JSON.stringify(resp.data));
          dispatch({ type: 'restore_login', user: resp.data });
        } else {
          localStorage.removeItem('user');
          localStorage.removeItem('token');
          dispatch({ type: 'logout' });
        }
      };

      if (user && token) {
        handleRestoreLogin();
      } else {
        dispatch({ type: 'set_loading', isLoading: false });
      }
    } catch (error) {
      localStorage.removeItem('user');
      localStorage.removeItem('token');
      dispatch({ type: 'logout' });
    }
  }, []);

  const setAppState = React.useMemo(
    () => ({
      login: async (user, token) => {
        dispatch({ type: 'login', user });
        localStorage.setItem('user', JSON.stringify(user));
        localStorage.setItem('token', token);
      },
      restoreLogin: async () => {
        dispatch({ type: 'restore_login' });
      },
      logout: () => {
        dispatch({ type: 'logout' });
        localStorage.removeItem('user');
      },
      setLoading: async (isLoading) => {
        dispatch({ type: 'set_loading', isLoading });
      },
      setUser: async (user) => {
        dispatch({ type: 'set_user', user });
      },
      setNotifications: async (notifications) => {
        dispatch({ type: 'set_notifications', notifications });
      },
    }),
    []
  );

  return (
    <AppContext.Provider value={[appState, setAppState]}>
      {children}
    </AppContext.Provider>
  );
};
