import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Home from './pages/admin/Home';
import Login from './pages/auth/Login';
import Recover from './pages/auth/Recover';
import Admin from './pages/admin/Admin';
import UsersList from './pages/admin/users/UsersList';
import UserForm from './pages/admin/users/UserForm';

import { AppContext } from './context/AppContext';

import MaterialsList from './pages/admin/materials/MaterialsList';
import MaterialForm from './pages/admin/materials/MaterialForm';
import EquipmentsList from './pages/admin/equipments/EquipmentsList';
import EquipmentForm from './pages/admin/equipments/EquipmentForm';
import ServicesList from './pages/admin/servicesTech/ServicesList';
import ServiceForm from './pages/admin/servicesTech/ServiceForm';
import ReportServiceForm from './pages/admin/servicesTech/ReportServiceForm';
import ServiceReceipt from './pages/admin/servicesTech/ServiceReceipt';
import ServiceReceipt2 from './pages/admin/servicesTech/ServiceReceipt2';
import ServicesClient from './pages/admin/servicesClient/ServicesClient';
import ServiceView from './pages/admin/servicesClient/ServiceView';

import UserProfile from './pages/admin/users/UserProfile';

import Loading from './components/Loading';
import ReportServices from './pages/admin/reports/ReportServices';

export default function RootRouter() {
  const [appState] = React.useContext(AppContext);
  console.log('appState', appState);

  if (appState.isLoading) {
    return <Loading />;
  }

  return (
    <BrowserRouter>
      <Routes>
        {appState.user ? (
          <Route path="/" element={<Admin />}>
            <Route path="/" element={<Home />} />
            <Route path="users" element={<UsersList />} />
            <Route path="users/new" element={<UserForm />} />
            <Route path="users/:id" element={<UserForm />} />
            <Route path="profile/:id" element={<UserProfile />} />
            <Route path="materials" element={<MaterialsList />} />
            <Route path="materials/new" element={<MaterialForm />} />
            <Route path="materials/:id" element={<MaterialForm />} />
            <Route path="equipments" element={<EquipmentsList />} />
            <Route path="equipments/new" element={<EquipmentForm />} />
            <Route path="equipments/:id" element={<EquipmentForm />} />
            <Route path="services" element={<ServicesList />} />
            <Route path="services/new" element={<ServiceForm />} />
            <Route path="services/:id" element={<ServiceForm />} />
            <Route path="services/report/:id" element={<ReportServiceForm />} />
            <Route path="services/receipt/:id" element={<ServiceReceipt />} />
            <Route path="services/receipt2/:id" element={<ServiceReceipt2 />} />
            <Route path="services/client" element={<ServicesClient />} />
            <Route path="services/client/:id" element={<ServiceView />} />
            <Route path="reports/services" element={<ReportServices />} />
          </Route>
        ) : (
          <>
            <Route path="/" element={<Login />} />
            <Route path="recover" element={<Recover />} />
          </>
        )}
        <Route path="*" element={<Login />} />
      </Routes>
    </BrowserRouter>
  );
}
