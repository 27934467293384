import React from 'react';
import TableCustom from '../../../components/TableCustom';
import { getServicesByClient } from '../../../services/ws';

import ContentHeader from '../../../components/ContentHeader';
import LinkCustom from '../../../components/LinkCustom';
import Loading from '../../../components/Loading';

import { lstStatesService } from '../../../utils/LstData';
import { AppContext } from '../../../context/AppContext';

import { lstTypeEquipments, lstBrands } from '../../../utils/LstData';

export default function ServicesClient() {
  const [appState] = React.useContext(AppContext);

  const columns = [
    {
      Header: '# Orden',
      accessor: 'id',
    },
    {
      Header: 'Fecha de ingeso',
      accessor: 'created_at',
    },
    {
      Header: 'Equipo',
      accessor: 'equipmentName',
    },
    {
      Header: 'Persona encargada',
      accessor: 'responsibleName',
    },
    {
      Header: 'Informe',
      accessor: 'client_report',
    },
    {
      Header: 'Estado',
      accessor: 'statusName',
    },
    {
      Header: 'Opciones',
      accessor: (props) => (
        <div className="flex flex-col items-center justify-center">
          <LinkCustom
            text="Comp. de ingreso"
            to={`/services/receipt/${props.id}`}
            className="text-xs"
          />
          {props.status === '3' && (
            <LinkCustom
              text="Comp. de entrega"
              to={`/services/receipt2/${props.id}`}
              className="text-xs"
            />
          )}
        </div>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  const handleGet = async () => {
    const resp = await getServicesByClient(appState.user.id);
    if (resp.success) {
      const dataProcessed = resp.data.map((item) => {
        return {
          ...item,
          equipmentName: nameEquipment(item.equipment),
          responsibleName:
            (item.responsible.name || '') +
            ' ' +
            (item.responsible.last_name || ''),
          statusName: lstStatesService.find(
            (state) => String(state.id) === String(item.status)
          )?.name,
          created_at: item.created_at.split('T')[0],
        };
      });
      setData(dataProcessed);
    }
    setIsLoading(false);
  };

  React.useEffect(() => {
    handleGet();
  }, []);

  const nameEquipment = (item) => {
    const type = lstTypeEquipments.find(
      (type) => String(type.id) === String(item.type)
    );
    const brand = lstBrands.find(
      (brand) => String(brand.id) === String(item.brand)
    );

    return `${type.name} ${brand.name} ${item.model}`;
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader title="Listado de servicios" text="Ingresar Orden" />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
