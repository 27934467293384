import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getMaterials,
  getService,
  putServiceReported,
  putServiceReportedFinished,
  putServiceReportedReopen,
} from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import { ButtonCustomRef } from '../../../components/ButtonCustomRef';
import {
  ContainerInput,
  InputVoid,
  InputCustom,
  SelectCustom,
  TextAreaCustom,
} from '../../../components/FormElements';

import {
  lstTypeEquipments,
  lstTypeMaterials,
  lstBrands,
  lstStatesService,
  lstTaxes,
} from '../../../utils/LstData';
import ModalCustom from '../../../components/ModalCustom';
import MaterialsAdd from './MaterialAdd';
import ButtonCustom from '../../../components/ButtonCustom';
import Loading from '../../../components/Loading';

import { AppContext } from '../../../context/AppContext';
import { getDateTimeLocal } from '../../../utils/getInfo';

export default function ReportServiceForm() {
  const [appState, setAppState] = React.useContext(AppContext);
  const idRole = appState.user.roles[0]?.id;

  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [lstStatus, setLstStatus] = React.useState([]);

  const [current, setCurrent] = React.useState({});

  const [user, setUser] = React.useState({});
  const [equipment, setEquipment] = React.useState({});

  const [lstMaterials, setLstMaterials] = React.useState([]);
  const [lstMaterialsUsed, setLstMaterialsUsed] = React.useState([]);

  const [showModal, setShowModal] = React.useState(false);
  const [disabled, setDesabled] = React.useState(false);

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getDataCurrent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    const calcPriceMaterials = () => {
      let price = 0;
      lstMaterialsUsed.forEach((material) => {
        const total = material.price * material.quantity;
        price += total;
      });

      setCurrent({ ...current, price_materials: price.toFixed(2) });
    };

    calcPriceMaterials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lstMaterialsUsed, current.price]);

  const handleLstStatus = (status) => {
    let lst = [];
    const stateCurrent = parseInt(status);
    if (stateCurrent === 2) {
      lst.push(lstStatesService[1]);
      lst.push(lstStatesService[2]);

      if (idRole === 1) lst.push(lstStatesService[7]);

      setLstStatus(lst);
      return;
    }

    if (stateCurrent === 3) {
      lst.push(lstStatesService[2]);

      if (idRole === 1) lst.push(lstStatesService[7]);
      setLstStatus(lst);
      return;
    }

    lst = lstStatesService.filter((item) => item.id !== 3 && item.id !== 8);
    setLstStatus(lst);
  };

  const getDataCurrent = async () => {
    const resp = await getService(id);
    if (resp.success) {
      handleLstStatus(resp.data.status);

      const data = resp.data;
      data.ci = data.client.ci;
      data.serie = data.equipment.serie;
      data.delivery_date = getDateTimeLocal(data.delivery_date);

      data.price = data.price ? data.price : '0.00';
      data.price_materials = '0.00';

      data.tax = data.tax ? data.tax : 1;

      if (data.materials) {
        const dataProcessed = data.materials.map((item) => {
          return {
            ...item,
            type: lstTypeMaterials.find(
              (type) => String(type.id) === String(item.type)
            )?.name,
            brand: lstBrands.find(
              (brand) => String(brand.id) === String(item.brand)
            )?.name,
            quantity: item.pivot.quantity,
          };
        });
        setLstMaterialsUsed(dataProcessed);
        await getLstMaterials(dataProcessed);
      } else {
        await getLstMaterials();
      }

      if (String(data.status) === '2' || String(data.status) === '3') {
        setDesabled(true);
      }

      setCurrent(resp.data);
      setUser(resp.data.client);
      setEquipment(resp.data.equipment);
    }
    setIsLoading(false);
  };

  const getLstMaterials = async (data) => {
    const resp = await getMaterials();
    if (resp.success) {
      const dataProcessed = resp.data.map((item) => {
        return {
          ...item,
          type: lstTypeMaterials.find(
            (type) => String(type.id) === String(item.type)
          )?.name,
          brand: lstBrands.find(
            (brand) => String(brand.id) === String(item.brand)
          )?.name,
        };
      });

      if (data) {
        const dataFilter = dataProcessed.filter((item) => {
          return !data.find((item2) => String(item2.id) === String(item.id));
        });
        setLstMaterials(dataFilter);
      } else {
        setLstMaterials(dataProcessed);
      }
    }
  };

  const handleChange = (e) => {
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btn && btn.current.setAttribute('disabled', true);

    try {
      const json = current;
      json.materials = lstMaterialsUsed.map((item) => {
        return { id: item.id, quantity: item.quantity };
      });

      if (parseInt(json.status) === 2) {
        Swal.fire({
          title: '¿Está seguro?',
          text: 'Una vez terminado el servicio, ya no va a ser posible modificar el informe',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, terminar servicio',
        }).then(async (result) => {
          if (result.value) {
            await finished(json);
          }
        });
      }

      if (parseInt(json.status) === 3) {
        json.delivery_id = appState.user.id;
        json.delivery_date_real = getDateTimeLocal();
        await save(json);
      }

      if (parseInt(json.status) === 8) {
        await reopen(json);
      }

      if (
        parseInt(json.status) !== 2 &&
        parseInt(json.status) !== 3 &&
        parseInt(json.status) !== 8
      ) {
        await save(json);
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }

    setTimeout(() => {
      setAppState.setNotifications(appState.notifications + 1);
    }, 1000);

    if (btn?.current) {
      btn.current.removeAttribute('disabled');
    }
  };

  const save = async (json) => {
    const resp = await putServiceReported(json);
    handleSwalSave(resp);
  };

  const finished = async (json) => {
    const resp = await putServiceReportedFinished(json);
    handleSwalSave(resp);
  };

  const reopen = async (json) => {
    const resp = await putServiceReportedReopen(json);
    handleSwalSave(resp);
  };

  const handleSwalSave = (resp) => {
    if (resp.success) {
      Swal.fire({
        icon: 'success',
        title: 'Se ha actualizado correctamente',
        text: '',
      });
      navigate('/services');
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Validación',
        text: resp.message,
      });
    }
  };

  const subTotal = () => {
    const price = current.price ? current.price : 0;
    const price_materials = current.price_materials
      ? current.price_materials
      : 0;

    return (parseFloat(price) + parseFloat(price_materials)).toFixed(2);
  };

  const total = () => {
    const price = current.price ? current.price : 0.0;
    const price_materials = current.price_materials
      ? current.price_materials
      : 0;
    const taxes =
      lstTaxes.find((tax) => String(tax.id) === String(current.tax))?.value /
      100;

    const total = (
      (parseFloat(price) + parseFloat(price_materials)) *
      (1 + taxes)
    ).toFixed(2);

    return total;
  };

  const handleAddMaterial = (item) => {
    const newLst = [...lstMaterialsUsed];
    item.quantity = 1;
    newLst.push(item);
    setLstMaterialsUsed(newLst);
    setShowModal(false);

    /* remove from lstMaterials */
    const newLstMaterials = lstMaterials.filter((material) => {
      return material.id !== item.id;
    });
    setLstMaterials(newLstMaterials);
  };

  const handleRemoveMaterial = (item) => {
    const newLst = [...lstMaterialsUsed];
    newLst.splice(item, 1);
    setLstMaterialsUsed(newLst);

    /* add to lstMaterials */
    const newLstMaterials = [...lstMaterials];
    newLstMaterials.push(item);
    setLstMaterials(newLstMaterials);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      <ContentHeader title="Servicio - Informe"></ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-current"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">Cliente</h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Cédula"
              name="ci"
              value={current.ci}
              n="2"
              required
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Nombre"
              name="name"
              value={user.name}
              n="2"
              required
              disabled
            />
            <InputCustom
              text="Apellido"
              name="last_name"
              value={user.last_name}
              n="2"
              required
              disabled
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Teléfono"
              name="phone"
              value={user.phone}
              n="2"
              disabled
            />
            <InputCustom
              text="Email"
              name="email"
              value={user.email}
              n="2"
              type="email"
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Dirección"
              name="address"
              value={user.address}
              disabled
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">
              Datos del equipo
            </h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Serie"
              name="serie"
              value={current.serie}
              n="2"
              required
              disabled
            />
            <InputCustom
              text="Tipo"
              name="type"
              value={
                lstTypeEquipments.find(
                  (x) => String(x.id) === String(equipment.type)
                )?.name
              }
              n="2"
              required
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Marca"
              name="brand"
              value={
                lstBrands.find((x) => String(x.id) === String(equipment.brand))
                  ?.name
              }
              n="2"
              required
              disabled
            />
            <InputCustom
              text="Modelo"
              name="model"
              value={equipment.model}
              n="2"
              required
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <TextAreaCustom
              text="Accesorios"
              name="accessories"
              value={current.accessories}
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <TextAreaCustom
              text="Falla reportada"
              name="failure"
              value={current.failure}
              disabled
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">Informes</h2>
          </div>

          <ContainerInput>
            <TextAreaCustom
              text="Informe Técnico"
              name="technical_report"
              value={current.technical_report}
              rows="9"
              onChange={handleChange}
              disabled={disabled}
            />
          </ContainerInput>

          <ContainerInput>
            <TextAreaCustom
              text="Informe al cliente"
              name="client_report"
              value={current.client_report}
              rows="5"
              onChange={handleChange}
              disabled={disabled}
            />
          </ContainerInput>

          <ContainerInput>
            <SelectCustom
              text="Estado"
              name="status"
              value={current.status}
              lst={lstStatus}
              onChange={handleChange}
              required
            />
          </ContainerInput>

          <div className="mt-8 flex flex-row justify-between p-3">
            <h2 className="text-xl font-bold text-gray-700">
              Materiales e insumos
            </h2>
            <ButtonCustom
              className="text-xs"
              text="Agregar"
              onClick={(e) => {
                e.preventDefault();
                setShowModal(true);
              }}
              disabled={disabled}
            />
          </div>

          {lstMaterialsUsed.length === 0 && (
            <div className="flex flex-col p-3">
              <h2 className="text-center font-bold text-gray-700">
                No hay materiales o insumos agregados
              </h2>
            </div>
          )}

          {lstMaterialsUsed.length > 0 &&
            lstMaterialsUsed.map((material, index) => (
              <div
                key={material.id}
                className="flex flex-row items-center justify-between"
              >
                <div className="">
                  <ContainerInput>
                    <InputCustom
                      text="Cantidad"
                      name="quantity"
                      value={material.quantity}
                      n="3"
                      onChange={(e) => {
                        setLstMaterialsUsed(
                          lstMaterialsUsed.map((x) => {
                            if (String(x.id) === String(material.id)) {
                              x.quantity = e.target.value;
                            }
                            return x;
                          })
                        );
                      }}
                      type="number"
                      min="1"
                      max={material.stock}
                      step="1"
                      required
                      disabled={disabled}
                    />
                    <InputCustom
                      text="Material"
                      name="material"
                      value={material.brand + ' ' + material.model}
                      n="3"
                      disabled
                    />
                    <InputCustom
                      text="Precio"
                      name="price"
                      value={material.price}
                      n="3"
                      type="number"
                      min="0"
                      step="0.01"
                      pattern="^\d+(\.\d{1,2})?$"
                      prefix="$"
                      disabled
                    />
                  </ContainerInput>
                </div>
                <div className="flex flex-col p-3">
                  <button
                    className="rounded bg-red-500 py-2 px-4 text-xs font-bold text-white hover:bg-red-700"
                    onClick={(e) => {
                      e.preventDefault();
                      handleRemoveMaterial(material);
                    }}
                    disabled={disabled}
                  >
                    Eliminar
                  </button>
                </div>
              </div>
            ))}

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">Costos</h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Mano de Obra"
              name="price"
              value={current.price}
              n="2"
              onChange={handleChange}
              type="number"
              min="0"
              step="0.01"
              pattern="^\d+(\.\d{1,2})?$"
              prefix="$"
              disabled={disabled}
            />
            <InputCustom
              text="Subtotal"
              name="subtotal"
              value={subTotal()}
              n="2"
              type="number"
              min="0"
              step="0.01"
              pattern="^\d+(\.\d{1,2})?$"
              prefix="$"
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Materiales e Insumos"
              name="price_materials"
              value={current.price_materials}
              n="2"
              type="number"
              min="0"
              step="0.01"
              pattern="^\d+(\.\d{1,2})?$"
              prefix="$"
              disabled
            />
            <SelectCustom
              text="Impuestos"
              name="tax"
              value={current.tax}
              n="2"
              onChange={handleChange}
              lst={lstTaxes}
              required
              disabled
            />
          </ContainerInput>

          <ContainerInput>
            <InputVoid />
            <InputCustom
              text="Total"
              name="total"
              value={total()}
              n="2"
              type="number"
              min="0"
              step="0.01"
              pattern="^\d+(\.\d{1,2})?$"
              prefix="$"
              disabled
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <ButtonCustomRef
              text="Guardar"
              ref={btn}
              form="form-current"
              type="submit"
              disabled={disabled && idRole !== 1}
            />
          </div>
        </form>
      </div>
      <ModalCustom
        showModal={showModal}
        setShowModal={setShowModal}
        title="Agregar Material e Insumo"
      >
        <MaterialsAdd
          lstMaterials={lstMaterials}
          handleAddMaterial={handleAddMaterial}
        />
      </ModalCustom>
    </div>
  );
}
