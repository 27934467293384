import React from 'react';
import TableCustom from '../../../components/TableCustom';
import {
  getServices,
  deleteService,
  getServicesByResponsible,
} from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import LinkCustom from '../../../components/LinkCustom';
import ButtonCustom from '../../../components/ButtonCustom';
import Loading from '../../../components/Loading';

import { lstStatesService } from '../../../utils/LstData';
import { AppContext } from '../../../context/AppContext';
import { ContainerButtonsTable } from '../../../components/OptionsButtons';

export default function ServicesList() {
  const [appState] = React.useContext(AppContext);

  const idRole = appState.user.roles[0]?.id;

  const columns = [
    {
      Header: '# Orden',
      accessor: 'id',
    },
    {
      Header: 'Fecha de ingreso',
      accessor: 'created_at',
    },
    {
      Header: 'Serie de equipo',
      accessor: 'equipment.serie',
    },
    {
      Header: 'Cliente',
      accessor: 'client_name',
    },
    {
      Header: 'Estado',
      accessor: 'statusName',
    },
    {
      Header: 'Responsable',
      accessor: 'responsible.name',
    },
    {
      Header: 'Opciones',
      accessor: (props) => (
        <ContainerButtonsTable>
          {idRole === 1 && (
            <LinkCustom
              text="Editar"
              to={`/services/${props.id}`}
              className="text-xs"
            />
          )}
          <LinkCustom
            text="Informe"
            to={`/services/report/${props.id}`}
            className="text-xs"
          />
          {props.status !== '2' && props.status !== '3' && (
            <LinkCustom
              text="Comprobante"
              to={`/services/receipt/${props.id}`}
              className="text-xs"
            />
          )}
          {(props.status === '2' || props.status === '3') && (
            <LinkCustom
              text="Comprobante"
              to={`/services/receipt2/${props.id}`}
              className="text-xs"
            />
          )}
          {idRole === 1 && (
            <ButtonCustom
              text="Eliminar"
              onClick={() => handleDelete(props.id)}
              className="text-xs"
              color="danger"
            />
          )}
        </ContainerButtonsTable>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    let resp = null;

    if (idRole === 1) {
      resp = await getServices();
    } else {
      resp = await getServicesByResponsible(appState.user.id);
    }

    if (resp.success) {
      const dataProcessed = resp.data.map((item) => {
        return {
          ...item,
          client_name: item.client.name + ' ' + item.client.last_name,
          statusName: lstStatesService.find(
            (state) => String(state.id) === String(item.status)
          )?.name,
          created_at: item.created_at.split('T')[0],
        };
      });
      setData(dataProcessed);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Una vez eliminado, no podrás recuperar este registro!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then(async (result) => {
      if (result.value) {
        const resp = await deleteService(id);
        if (resp.success) {
          Swal.fire('Eliminado!', resp.message, 'success');
          handleGet();
        }
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader
        to={`/services/new`}
        title="Listado de servicios"
        text="Ingresar Orden"
      />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
