import React from 'react';
import TableCustom from '../../../components/TableCustom';
import { getEquipments, deleteEquipment } from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import LinkCustom from '../../../components/LinkCustom';
import ButtonCustom from '../../../components/ButtonCustom';
import Loading from '../../../components/Loading';

import { lstTypeEquipments, lstBrands } from '../../../utils/LstData';
import { AppContext } from '../../../context/AppContext';
import { ContainerButtonsTable } from '../../../components/OptionsButtons';

export default function EquipmentsList() {
  const [appState] = React.useContext(AppContext);

  const idRole = appState.user.roles[0]?.id;

  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
    },
    {
      Header: 'Serie',
      accessor: 'serie',
    },
    {
      Header: 'Marca',
      accessor: 'brand',
    },
    {
      Header: 'Modelo',
      accessor: 'model',
    },
    {
      Header: 'Tipo',
      accessor: 'type',
    },
    {
      Header: 'Opciones',
      accessor: (props) => (
        <ContainerButtonsTable>
          <LinkCustom
            text="Editar"
            to={`/equipments/${props.id}`}
            className="text-xs"
          />
          {idRole === 1 && (
            <ButtonCustom
              text="Eliminar"
              onClick={() => handleDelete(props.id)}
              className="text-xs"
              color="danger"
            />
          )}
        </ContainerButtonsTable>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    const resp = await getEquipments();
    if (resp.success) {
      const dataProcessed = resp.data.map((item) => {
        return {
          ...item,
          brand: lstBrands.find(
            (brand) => String(brand.id) === String(item.brand)
          )?.name,
          type: lstTypeEquipments.find(
            (type) => String(type.id) === String(item.type)
          )?.name,
        };
      });
      setData(dataProcessed);
    }
    setIsLoading(false);
  };

  const handleDelete = async (id) => {
    Swal.fire({
      title: '¿Estás seguro?',
      text: 'Una vez eliminado, no podrás recuperar este registro!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, eliminar!',
    }).then(async (result) => {
      if (result.value) {
        const resp = await deleteEquipment(id);
        if (resp.success) {
          Swal.fire('Eliminado!', resp.message, 'success');
          handleGet();
        }
      }
    });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader to={`/equipments/new`} title="Listado de Equipos" />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
