import React from "react";
import BounceLoader from "react-spinners/BounceLoader";

export default function Loading() {
  return (
    <div className="flex min-h-[80vh] items-center justify-center">
      <BounceLoader color="#3b82f6" loading={true} size={130} />
    </div>
  );
}
