import React from 'react';
import { useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import ButtonCustom from '../../../components/ButtonCustom';
import { InfoReceipt, ParaReceipt } from '../../../components/InfoReceipt';
import { getService, putServiceReported } from '../../../services/ws';
import Loading from '../../../components/Loading';

import { lstTypeEquipments, lstBrands, lstTaxes } from '../../../utils/LstData';
import { firm, direction, Description2 } from '../../../utils/infoData';

import { AppContext } from '../../../context/AppContext';

import Swal from 'sweetalert2';
import { getDateTimeLocal } from '../../../utils/getInfo';

export default function ServiceReceipt2() {
  const [appState] = React.useContext(AppContext);
  const idRole = appState.user.roles[0]?.id;

  const { id } = useParams();
  const componentRef = React.useRef();

  const [current, setCurrent] = React.useState({});
  const [lstMaterials, setLstMaterials] = React.useState([]);
  const [total, setTotal] = React.useState(0.0);
  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    getDataCurrent(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const getName = (infoUser) => {
    if (!infoUser) {
      return '';
    }
    let nameText = '';
    if (infoUser.name) {
      nameText += infoUser.name;
    }
    if (infoUser.last_name) {
      nameText += ` ${infoUser.last_name}`;
    }
    return nameText;
  };

  const getDataCurrent = async (idCurrent) => {
    if (idCurrent) {
      const resp = await getService(idCurrent);

      if (resp.success) {
        const { data } = resp;
        const dataProcessed = {
          ...data,
          delivery_name: getName(data.delivery),
          client_name:
            (data.client.name || '') + ' ' + (data.client.last_name || ''),
          created_at: getDateTimeLocal(data.created_at),
          equipment_brand: lstBrands.find(
            (brand) => String(brand.id) === String(data.equipment.brand)
          )?.name,
          equipment_type: lstTypeEquipments.find(
            (type) => String(type.id) === String(data.equipment.type)
          )?.name,
          tax: data.tax ? data.tax : 1,
        };

        setCurrent(dataProcessed);

        const lstMaterialsProcessed = data.materials.map((material) => ({
          ...material,
          brand: lstBrands.find(
            (brand) => String(brand.id) === String(material.brand)
          )?.name,
          quantity: material.pivot.quantity,
        }));

        setLstMaterials(lstMaterialsProcessed);
      }
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    const total = () => {
      const price = current.price ? current.price : 0.0;
      const price_materials = lstMaterials.reduce(
        (acc, cur) => acc + cur.price * cur.quantity,
        0.0
      );
      const taxes =
        lstTaxes.find((tax) => String(tax.id) === String(current.tax))?.value /
        100;

      const total = (
        (parseFloat(price) + parseFloat(price_materials)) *
        (1 + taxes)
      ).toFixed(2);

      setTotal(total);
    };

    total();
  }, [current, lstMaterials]);

  const handleDelivery = async () => {
    const data = current;
    data.status = 3;
    data.delivery_id = appState.user.id;
    data.delivery_date_real = getDateTimeLocal();
    await save(data);
  };

  const save = async (json) => {
    console.log(json);
    const resp = await putServiceReported(json);
    if (resp.success) {
      Swal.fire({
        icon: 'success',
        title: 'Se ha actualizado correctamente',
        text: '',
      });
      getDataCurrent(id);
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Validación',
        text: resp.message,
      });
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="">
      <div className="">
        {current.status === '3' && (
          <ReactToPrint
            trigger={() => <ButtonCustom text="Imprimir" />}
            content={() => componentRef.current}
          />
        )}
        {current.status === '2' && idRole !== '3' && (
          <ButtonCustom text="Entregar" onClick={handleDelivery} />
        )}
      </div>
      <div className="overflow-scroll">
        <div
          ref={componentRef}
          className="relative w-[1120px] border p-8 font-mono"
        >
          <div className="absolute top-0 right-0">
            <div className="m-6 flex items-center border-2 border-slate-500 py-2 px-4">
              <label className="mr-3 text-xl font-semibold underline">
                Orden:{' '}
              </label>
              <label className="text-xl font-semibold">{current.id}</label>
            </div>
          </div>

          <div className="flex flex-col items-center">
            <p className="text-2xl font-semibold underline">
              GSTECNICO-ENTREGA E INFORME TÉCNICO
            </p>
            <p className="">{direction}</p>
          </div>

          <div>
            <div className="mt-3 flex">
              <div className="w-1/2">
                <InfoReceipt
                  name="Fecha de entrega:"
                  value={
                    current.status === '3' ? current.delivery_date_real : ''
                  }
                  minWidthName={false}
                />
              </div>
              <div className="w-1/2">
                <InfoReceipt
                  name="Entregado por:"
                  value={current.status === '3' ? current.delivery_name : ''}
                  minWidthName={false}
                />
              </div>
            </div>

            <div className="mt-3 flex">
              <div className="w-1/2">
                <InfoReceipt name="Nombres:" value={current.client_name} />
                <InfoReceipt
                  name="Dirección:"
                  value={current.client?.address}
                />
              </div>
              <div className="w-1/2">
                <InfoReceipt name="Celular:" value={current.client?.phone} />
                <InfoReceipt name="Correo" value={current.client?.email} />
              </div>
            </div>

            <div className="mt-3 flex">
              <div className="w-1/2">
                <InfoReceipt
                  name="Tipo de Equipo:"
                  value={current.equipment_type}
                />
                <InfoReceipt name="Modelo:" value={current.equipment?.model} />
              </div>
              <div className="w-1/2">
                <InfoReceipt name="Marca:" value={current.equipment_brand} />
                <InfoReceipt name="Serie:" value={current.equipment?.serie} />
              </div>
            </div>

            <div className="mt-3">
              <ParaReceipt
                name="ACCESORIOS Y ADICIONALES ENTREGADOS:"
                value={current.accessories}
              />
            </div>

            <div className="mt-3">
              <ParaReceipt
                name="FALLA REPORTADA / TRABAJO SOLICITADO:"
                value={current.failure}
              />
            </div>

            <div className="mt-3">
              <ParaReceipt
                name="INFORME TÉCNICO DEL TRABAJO REALIZADO:"
                value={current.client_report}
              />
            </div>

            <div className="mt-3">
              <div className="flex flex-col">
                <div className="font-semibold">
                  COSTO INSUMOS/MATERIALES/SERVICIO:
                </div>
                <div className="w-full">
                  <div className="flex">
                    <div className="w-1/2">
                      <div className="">Mano de Obra</div>
                    </div>
                    <div className="w-1/2">
                      <div className="w-36 text-right">{current.price}</div>
                    </div>
                  </div>
                  {lstMaterials &&
                    lstMaterials.length > 0 &&
                    lstMaterials.map((material) => (
                      <div key={material.id} className="flex">
                        <div className="w-1/2">
                          <div className="">
                            {material.quantity +
                              ' ' +
                              material.brand +
                              ' ' +
                              material.model}
                          </div>
                        </div>
                        <div className="w-1/2">
                          <div className="w-36 text-right">
                            {material.price}
                          </div>
                        </div>
                      </div>
                    ))}
                  <div className="flex">
                    <div className="w-1/2">
                      <div className="">Total (con IVA)</div>
                    </div>
                    <div className="w-1/2">
                      <div className="w-36 text-right">${total}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-11 mb-3 flex">
              <div className="w-1/2">
                <div className="flex items-center justify-center">
                  <div className="pr-6 font-semibold underline">{firm}</div>
                  <div className="">______________________________</div>
                </div>
              </div>
              <div className="w-1/2">
                <div className="flex items-center justify-center">
                  <div className="pr-6 font-semibold underline">CLIENTE:</div>
                  <div className="">______________________________</div>
                </div>
              </div>
            </div>

            <Description2 />
          </div>
        </div>
      </div>
    </div>
  );
}
