import React from 'react';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import { UserCircleAlt, Bell } from 'iconoir-react';

import { AppContext } from '../../context/AppContext';
import Navbar from '../../components/Navbar';

import { getLowStock } from '../../services/ws';

export default function Admin() {
  const [appState, setAppState] = React.useContext(AppContext);
  const idRole = appState.user.roles[0]?.id;
  const navigate = useNavigate();

  const [lowStock, setLowStock] = React.useState([]);

  const handleLowStock = async () => {
    const response = await getLowStock();
    if (response.success) {
      setLowStock(response.data);
    }
  };

  React.useEffect(() => {
    handleLowStock();
  }, [appState.notifications]);

  const closeSession = async () => {
    navigate('/');
    setAppState.logout();
  };

  return (
    <div className="flex min-h-screen bg-gradient-to-t from-gray-300 to-blue-500">
      <Navbar closeSession={closeSession} />
      <div className="w-[calc(100vw-260px)] rounded-2xl px-5">
        <div className="flex h-20 flex-row items-center justify-between px-4">
          <h2 className="text-lg font-semibold text-white">
            Panel de administración
          </h2>
          <div className="flex flex-row items-center">
            {lowStock.length > 0 && idRole === 1 && (
              <Link to="/materials" className="m-4 px-2">
                <div className="flex flex-row items-center">
                  <span className="rounded-full bg-red-500 px-3 py-1 text-xs text-white">
                    <Bell />
                  </span>
                  <span className="ml-2 font-medium text-white">
                    Stock bajo
                  </span>
                </div>
              </Link>
            )}

            <Link
              to={`/profile/${appState.user.id}`}
              className="flex flex-row items-center px-2 py-1 hover:rounded hover:shadow-lg"
            >
              <UserCircleAlt className="text-white" />
              <span className="mx-3 ml-2 text-lg font-semibold text-white">
                {appState.user.name} ({appState.user.roles[0]?.name})
              </span>
              {/* <Settings className="text-white" /> */}
            </Link>
          </div>
        </div>
        <div className="min-h-[calc(100vh-100px)] rounded-2xl border bg-white px-6 py-4 shadow-xl">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
