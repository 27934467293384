import React from 'react';
import { Link } from 'react-router-dom';
import { styleCustom } from '../utils/styleCustom';

export default function LinkCustom({
  text,
  color = 'primary',
  className,
  to,
  ...props
}) {
  const classCustom = styleCustom(color);

  return (
    <Link
      className={
        'm-0.5 rounded-full py-2 px-4 font-semibold ' + classCustom + className
      }
      to={to}
      {...props}
    >
      {text}
    </Link>
  );
}
