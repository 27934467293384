export const getDateTimeLocal = (dateString) => {
  const date = dateString ? new Date(dateString) : new Date();
  const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const month =
    date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1;
  const year =
    date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();
  const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
  const minutes =
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
  const seconds =
    date.getSeconds() < 10 ? `0${date.getSeconds()}` : date.getSeconds();

  return `${year}-${month}-${day} ${hour}:${minutes}:${seconds}`;
};

export const getDate = (date) => {
  if (!date) {
    date = new Date();
  }

  const dateTime = new Date(date);
  const day = dateTime.getDate();
  const dayString = day < 10 ? `0${day}` : day;
  const month = dateTime.getMonth() + 1;
  const monthString = month < 10 ? `0${month}` : month;
  const year = dateTime.getFullYear();

  return `${year}-${monthString}-${dayString}`;
};

export const getCurrency = (value) => {
  if (!value) {
    return '$0.00';
  }

  const currency = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  }).format(value);

  return currency;
};
