import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getMaterial, postMaterial, putMaterial } from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import { ButtonCustomRef } from '../../../components/ButtonCustomRef';
import {
  ContainerInput,
  InputCustom,
  SelectCustom,
} from '../../../components/FormElements';

import { lstTypeMaterials, lstBrands } from '../../../utils/LstData';
import image from '../../../assets/images/150.png';
import Loading from '../../../components/Loading';

import { AppContext } from '../../../context/AppContext';

export default function MaterialForm() {
  const [appState, setAppState] = React.useContext(AppContext);
  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [current, setCurrent] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    handleGet();
  }, []);

  React.useEffect(() => {
    getDataCurrent(id);
  }, [id]);

  const getDataCurrent = async (idCurrent) => {
    if (idCurrent) {
      const resp = await getMaterial(idCurrent);
      if (resp.success) {
        setCurrent(resp.data);
      }
    }
    setIsLoading(false);
  };

  const handleGet = async () => {};

  const handleChange = (e) => {
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const handleChangeImage = (e) => {
    let file = e.target.files[0] || e.dataTransfer.files[0];
    if (!file) return;
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire({
        title: 'Error',
        text: 'El archivo debe ser una imagen',
        icon: 'error',
      });
      return;
    }
    if (file.size > 5000000) {
      Swal.fire({
        title: 'Error',
        text: 'El archivo debe ser menor a 5MB',
        icon: 'error',
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = (er) => {
      setCurrent({ ...current, image: er.target.result });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btn && btn.current.setAttribute('disabled', true);

    try {
      if (id) {
        const resp = await putMaterial(current);
        if (resp.success) {
          Swal.fire({
            icon: 'success',
            title: 'Se ha actualizado correctamente',
            text: '',
          });
          navigate('/materials');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Validación',
            text: resp.message,
          });
        }
      } else {
        const resp = await postMaterial(current);
        if (resp.success) {
          Swal.fire({
            icon: 'success',
            title: 'Se ha registrado correctamente',
            text: '',
          });
          navigate('/materials');
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Validación',
            text: resp.message,
          });
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }
    setAppState.setNotifications(appState.notifications + 1);
    if (btn?.current) {
      btn.current.removeAttribute('disabled');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      <ContentHeader
        title={
          id ? 'Editar Materiales e Insumos' : 'Registrar Materiales e Insumos'
        }
      >
        <ButtonCustomRef
          text="Guardar"
          ref={btn}
          form="form-current"
          type="submit"
        />
      </ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-current"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <div className="flex justify-center">
            <img
              className="w-44 rounded-xl"
              src={current.image ? current.image : image}
              alt="Imagen"
            />
          </div>
          <ContainerInput>
            <InputCustom
              text="Foto"
              name="image"
              onChange={handleChangeImage}
              type="file"
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Serie"
              name="serie"
              value={current.serie}
              n="2"
              onChange={handleChange}
              required
            />
            <SelectCustom
              text="Tipo"
              name="type"
              value={current.type}
              n="2"
              onChange={handleChange}
              lst={lstTypeMaterials}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <SelectCustom
              text="Marca"
              name="brand"
              value={current.brand}
              n="2"
              onChange={handleChange}
              lst={lstBrands}
              required
            />
            <InputCustom
              text="Modelo"
              name="model"
              value={current.model}
              n="2"
              onChange={handleChange}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Descripción"
              name="description"
              value={current.description}
              onChange={handleChange}
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Stock"
              name="stock"
              value={current.stock}
              n="2"
              onChange={handleChange}
              type="number"
              min="0"
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Costo"
              name="cost"
              value={current.cost}
              n="2"
              onChange={handleChange}
              type="number"
              min="0"
              step="0.01"
              pattern="^\d+(\.\d{1,2})?$"
              prefix="$"
              required
            />
            <InputCustom
              text="Precio"
              name="price"
              value={current.price}
              n="2"
              onChange={handleChange}
              type="number"
              min="0"
              step="0.01"
              pattern="^\d+(\.\d{1,2})?$"
              prefix="$"
              required
            />
          </ContainerInput>
        </form>
      </div>
    </div>
  );
}
