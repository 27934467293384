export const lstTypeMaterials = [
  {
    id: 1,
    name: "Material",
  },
  {
    id: 2,
    name: "Insumo",
  },
  {
    id: 3,
    name: "Repuesto",
  },
];

export const lstTypeEquipments = [
  {
    id: 1,
    name: "Laptop",
  },
  {
    id: 2,
    name: "Impresora",
  },
  {
    id: 3,
    name: "Cpu",
  },
  {
    id: 4,
    name: "Pantalla",
  },
  {
    id: 5,
    name: "Celular",
  },
  {
    id: 6,
    name: "Tablet",
  },
  {
    id: 7,
    name: "Router",
  },
  {
    id: 8,
    name: "Switch",
  },
  {
    id: 9,
    name: "Cámara",
  },
  {
    id: 10,
    name: "Proyector",
  },
  {
    id: 11,
    name: "Disco",
  },
  {
    id: 12,
    name: "Equipo de Red",
  },
  {
    id: 13,
    name: "Equipo de Cómputo",
  },
  {
    id: 14,
    name: "Periferico",
  },
  {
    id: 15,
    name: "Otros",
  },
];

export const lstBrands = [
  {
    id: 1,
    name: "Apple",
  },
  {
    id: 2,
    name: "Dell",
  },
  {
    id: 3,
    name: "Lenovo",
  },
  {
    id: 4,
    name: "HP",
  },
  {
    id: 5,
    name: "Asus",
  },
  {
    id: 6,
    name: "Acer",
  },
  {
    id: 7,
    name: "Alienware",
  },
  {
    id: 8,
    name: "Razer",
  },
  {
    id: 9,
    name: "Microsoft",
  },
  {
    id: 10,
    name: "Msi",
  },
  {
    id: 11,
    name: "Sony",
  },
  {
    id: 12,
    name: "Verbatim",
  },
  {
    id: 13,
    name: "Seagate",
  },
  {
    id: 14,
    name: "Hitachi",
  },
  {
    id: 15,
    name: "Samsung",
  },
  {
    id: 16,
    name: "Xtratech",
  },
  {
    id: 17,
    name: "Toshiba",
  },
  {
    id: 18,
    name: "Huawei",
  },
  {
    id: 19,
    name: "Crucial",
  },
  {
    id: 20,
    name: "Sandisk",
  },
  {
    id: 21,
    name: "Trust",
  },
  {
    id: 22,
    name: "Trendnet",
  },
  {
    id: 23,
    name: "Adata",
  },
  {
    id: 24,
    name: "Cdp",
  },
  {
    id: 25,
    name: "Benq",
  },
  {
    id: 26,
    name: "Trascend",
  },
  {
    id: 27,
    name: "Tripplite",
  },
  {
    id: 28,
    name: "Nec",
  },
  {
    id: 29,
    name: "Lexmark",
  },
  {
    id: 30,
    name: "Dahua",
  },
  {
    id: 31,
    name: "Intel",
  },
  {
    id: 32,
    name: "Biostart",
  },
  {
    id: 33,
    name: "Amd",
  },
  {
    id: 34,
    name: "Hikvision",
  },
  {
    id: 35,
    name: "Asrock",
  },
  {
    id: 36,
    name: "Gigabyte",
  },
  {
    id: 37,
    name: "Esc",
  },
  {
    id: 38,
    name: "Ins",
  },
  {
    id: 39,
    name: "Speed",
  },
  {
    id: 40,
    name: "Delta",
  },
  {
    id: 41,
    name: "Genius",
  },
  {
    id: 42,
    name: "Tplink",
  },
  {
    id: 43,
    name: "Dlink",
  },
  {
    id: 44,
    name: "Ibm",
  },
  {
    id: 45,
    name: "Kaspersky",
  },
  {
    id: 46,
    name: "Eset",
  },
  {
    id: 47,
    name: "Mcafee",
  },
  {
    id: 48,
    name: "Cisco",
  },
  {
    id: 49,
    name: "Zkteco",
  },
  {
    id: 50,
    name: "Kingston",
  },
  {
    id: 51,
    name: "Western Digital",
  },
  {
    id: 52,
    name: "Lg",
  },
  {
    id: 53,
    name: "Klip",
  },
  {
    id: 54,
    name: "Linksys",
  },
  {
    id: 55,
    name: "Apc",
  },
  {
    id: 56,
    name: "Epson",
  },
  {
    id: 57,
    name: "Canon",
  },
  {
    id: 58,
    name: "Brother",
  },
  {
    id: 59,
    name: "Nexxt",
  },
  {
    id: 60,
    name: "Logitech",
  },
  {
    id: 61,
    name: "Htc",
  },
  {
    id: 62,
    name: "Zebra",
  },
  {
    id: 63,
    name: "Generico",
  },
  {
    id: 64,
    name: "Otros",
  },
];

export const lstStatesService = [
  {
    id: 1,
    name: "Sin Revisar",
  },
  {
    id: 2,
    name: "Trabajo terminado",
  },
  {
    id: 3,
    name: "Entregado",
  },
  {
    id: 4,
    name: "En reparación",
  },
  {
    id: 5,
    name: "Esperando repuestos",
  },
  {
    id: 6,
    name: "Esperando respuesta del cliente",
  },
  {
    id: 7,
    name: "En proceso de garantía",
  },
  {
    id: 8,
    name: "Regresa",
  },
];

export const lstTaxes = [
  {
    id: 1,
    name: "IVA 12%",
    value: 12,
  },
];
