import React from "react";
import { styleCustom } from "../utils/styleCustom";

export const ButtonCustomRef = React.forwardRef(
  ({ text, color = "primary", className, ...props }, ref) => {
    const classCustom = styleCustom(color);

    return (
      <button
        ref={ref}
        className={
          "rounded-full py-2 px-4 font-bold " + classCustom + className
        }
        {...props}
      >
        {text}
      </button>
    );
  }
);
