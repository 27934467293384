import React from 'react';
import TableCustom from '../../../components/TableCustom';
import { getServicesByResponsible } from '../../../services/ws';

import ContentHeader from '../../../components/ContentHeader';
import LinkCustom from '../../../components/LinkCustom';
import Loading from '../../../components/Loading';

import { lstStatesService } from '../../../utils/LstData';
import { AppContext } from '../../../context/AppContext';
import { ContainerButtonsTable } from '../../../components/OptionsButtons';

export default function ServicesTechnical() {
  const [appState] = React.useContext(AppContext);

  const idRole = appState.user.roles[0]?.id;

  const columns = [
    {
      Header: '# Orden',
      accessor: 'id',
    },
    {
      Header: 'Fecha de ingreso',
      accessor: 'created_at',
    },
    {
      Header: 'Posible fecha de entrega',
      accessor: (props) => {
        if (new Date(props.delivery_date) < new Date()) {
          return <span className="text-red-500">{props.delivery_date}</span>;
        }
        return <span className="text-green-500">{props.delivery_date}</span>;
      },
    },
    {
      Header: 'Serie de equipo',
      accessor: 'equipment.serie',
    },
    {
      Header: 'Cliente',
      accessor: 'client_name',
    },
    {
      Header: 'Estado',
      accessor: 'statusName',
    },
    {
      Header: 'Opciones',
      accessor: (props) => (
        <ContainerButtonsTable>
          {idRole === 1 && (
            <LinkCustom
              text="Editar"
              to={`/services/${props.id}`}
              className="text-xs"
            />
          )}
          <LinkCustom
            text="Informe"
            to={`/services/report/${props.id}`}
            className="text-xs"
          />
          {props.status !== '2' && props.status !== '3' && (
            <LinkCustom
              text="Comprobante"
              to={`/services/receipt/${props.id}`}
              className="text-xs"
            />
          )}
          {(props.status === '2' || props.status === '3') && (
            <LinkCustom
              text="Comprobante"
              to={`/services/receipt2/${props.id}`}
              className="text-xs"
            />
          )}
        </ContainerButtonsTable>
      ),
    },
  ];

  const [isLoading, setIsLoading] = React.useState(true);

  const [data, setData] = React.useState([]);

  React.useEffect(() => {
    handleGet();
  }, []);

  const handleGet = async () => {
    const resp = await getServicesByResponsible(appState.user.id);

    if (resp.success) {
      const dataFilter = resp.data.filter((item) => item.status !== 3);

      const dataProcessed = dataFilter.map((item) => {
        return {
          ...item,
          client_name: item.client.name + ' ' + item.client.last_name,
          statusName: lstStatesService.find(
            (state) => String(state.id) === String(item.status)
          )?.name,
          created_at: item.created_at.split('T')[0],
        };
      });
      setData(dataProcessed);
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ContentHeader
        to={`/services/new`}
        title="Agenda de servicios"
        text="Ingresar Orden"
      />
      <TableCustom columns={columns} data={data} />
    </>
  );
}
