import React from 'react';
import TableCustom from '../../../components/TableCustom';

import ButtonCustom from '../../../components/ButtonCustom';
import { ContainerButtonsTable } from '../../../components/OptionsButtons';

export default function MaterialsAdd({ lstMaterials, handleAddMaterial }) {
  const columns = [
    {
      Header: 'Serie',
      accessor: 'serie',
    },
    {
      Header: 'Marca',
      accessor: 'brand',
    },
    {
      Header: 'Modelo',
      accessor: 'model',
    },
    {
      Header: 'Tipo',
      accessor: 'type',
    },
    {
      Header: 'Stock',
      accessor: 'stock',
    },
    {
      Header: 'Precio',
      accessor: 'price',
    },
    {
      Header: 'Opciones',
      accessor: (props) => (
        <ContainerButtonsTable>
          <ButtonCustom
            text="Agregar"
            onClick={() => handleAddMaterial(props)}
            className="text-sm"
          />
        </ContainerButtonsTable>
      ),
    },
  ];

  const [data, setData] = React.useState(lstMaterials);

  return <TableCustom columns={columns} data={data} minHeight={false} />;
}
