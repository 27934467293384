import React from 'react';
import { useTable, useGlobalFilter, usePagination } from 'react-table';

export default function TableSimple({ columns, data }) {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  const handleStyleHeader = (column) => {
    if (column.Header === 'ID' || column.Header === '# Orden') {
      return 'p-2 text-left text-pri text-xs w-20';
    }
    if (column.Header === 'Opciones') {
      return 'p-2 text-left text-pri text-xs w-64';
    }
    return 'p-2 text-left text-pri text-xs';
  };

  const handleStyleCell = (column) => {
    return 'px-2 py-1 text-sm';
  };

  return (
    <>
      <div className="p-2">
        <table
          {...getTableProps()}
          className="w-full min-w-[720px] table-fixed"
        >
          <thead>
            {
              // Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {
                    // Loop over the headers in each row
                    headerGroup.headers.map((column, j) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps()}
                        className={handleStyleHeader(column)}
                        key={j}
                      >
                        {
                          // Render the header
                          column.render('Header')
                        }
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {
              // Loop over the table rows
              rows.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr
                    {...row.getRowProps()}
                    className="border-t border-gray-200"
                  >
                    {
                      // Loop over the rows cells
                      row.cells.map((cell, j) => {
                        // Apply the cell props
                        return (
                          <td
                            {...cell.getCellProps()}
                            className={handleStyleCell(cell)}
                            key={j}
                          >
                            {
                              // Render the cell contents
                              cell.render('Cell')
                            }
                          </td>
                        );
                      })
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </>
  );
}
