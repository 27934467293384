import React from 'react';
import { getDate } from '../utils/getInfo';

export const ContainerInput = ({ children }) => {
  return <div className="mb-3 flex flex-wrap">{children}</div>;
};

export const InputVoid = () => {
  return <div className="w-full p-3 md:w-1/2" />;
};

export const InputCustom = ({
  text,
  name,
  value,
  type = 'text',
  n = '1',
  prefix = null,
  rightContent = null,
  ...props
}) => {
  return (
    <div
      className={
        'w-full px-3 ' +
        (n === '2' ? ' md:w-1/2' : '') +
        (n === '3' ? ' md:w-1/3' : '') +
        (n === '4' ? ' md:w-1/4' : '')
      }
    >
      <label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {text}
      </label>
      {prefix && (
        <div className="flex flex-row items-center">
          <label className="mr-2 block text-sm font-bold text-gray-700">
            {prefix}
          </label>
          <input
            name={name}
            value={value || ''}
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
            type={type}
            placeholder={text}
            {...props}
          />
        </div>
      )}
      {rightContent && (
        <div className="flex flex-row items-center">
          <input
            name={name}
            value={value || ''}
            className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
            type={type}
            placeholder={text}
            {...props}
          />

          {rightContent}
        </div>
      )}
      {!prefix && !rightContent && (
        <input
          name={name}
          value={value || ''}
          className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
          type={type}
          placeholder={text}
          {...props}
        />
      )}
    </div>
  );
};

export const SelectCustom = ({
  text,
  name,
  value,
  options,
  n = '1',
  lst,
  ...props
}) => {
  return (
    <div
      className={
        'w-full px-3 ' +
        (n === '2' ? ' md:w-1/2' : '') +
        (n === '3' ? ' md:w-1/3' : '') +
        (n === '4' ? ' md:w-1/4' : '')
      }
    >
      <label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {text}
      </label>
      <select
        name={name}
        value={value ?? ''}
        className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 pr-8 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
        {...props}
      >
        <option value="">Seleccione</option>
        {lst.map((item) => (
          <option key={item.id} value={item.id}>
            {item.name + (item.last_name ? ' ' + item.last_name : '')}
          </option>
        ))}
      </select>
    </div>
  );
};

export const TextAreaCustom = ({
  text,
  name,
  value,
  rows = '3',
  n = '1',
  ...props
}) => {
  return (
    <div className={'w-full px-3 ' + (n === '2' ? ' md:w-1/2' : '')}>
      <label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {text}
      </label>
      <textarea
        name={name}
        value={value || ''}
        rows={rows}
        className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 text-sm leading-tight text-gray-700 focus:border-gray-500 focus:bg-white focus:outline-none"
        {...props}
      />
    </div>
  );
};

export const InputDate = ({
  text,
  name,
  value = getDate(),
  n = '1',
  ...props
}) => {
  return (
    <div
      className={
        'w-full px-3 ' +
        (n === '2' ? ' md:w-1/2' : '') +
        (n === '3' ? ' md:w-1/3' : '') +
        (n === '4' ? ' md:w-1/4' : '')
      }
    >
      <label className="mb-2 block text-xs font-bold uppercase tracking-wide text-gray-700">
        {text}
      </label>
      <input
        name={name}
        value={value ?? ''}
        className="block w-full appearance-none rounded border border-gray-200 bg-gray-200 py-2 px-4 text-sm leading-tight text-gray-700 focus:bg-gray-300 focus:outline-none"
        type="date"
        {...props}
      />
    </div>
  );
};
