import React from "react";

export const firm = "COMERCIAL MACHADO";

export const direction =
  "Guayaquil y Colón, CENTRO COMERCIAL MACHADO - Riobamba - Teléfono 0987751349";

export function Description() {
  return (
    <div>
      <p className="text-justify text-sm">
        Al ingresar un equipo a nuestro Centro Especializado de Servicio Técnico
        el personal podría encontrar fallos adicionales a los reportados por
        usted en cuyo caso se procederá a informarle sobre el hecho y bajo
        ningún concepto se podrá responsabilizar al técnico por la ocurrencia de
        fallas no mencionadas al momento del ingreso.
      </p>
      <p className="mt-2 text-justify text-sm">
        Toda orden de reparación tendrá un costo mínimo de diez dólares por
        concepto de revisión y diagnóstico. Una vez encontrada la solución dicho
        valor será abonado al costo total de la reparación previo acuerdo con el
        cliente, en el caso de que el cliente no desee la reparación cancelará
        el costo de la revisión y diagnóstico.
      </p>
      <p className="mt-2 text-justify text-sm">
        <b>AUTORIZACIÓN:</b> Yo, el CLIENTE, en caso de no retirar mi equipo en
        reparación en un lapso de 60 días a partir de la fecha de ingreso,
        AUTORIZO a GSTECNICO a reciclar el mismo. Al firmar este documento
        ACEPTO todas las condiciones mencionadas.
      </p>
      <p className="mt-2 text-justify text-sm">
        <b>
          PUEDE ACCEDER A GSTECNICO EN LINEA PARA CONSULTAR ESTADO Y NOVEDADES
          DE SU EQUIPO EN: www.gstecnico.com / usuario y contraseña: cédula de
          identidad registrada con el equipo
        </b>
      </p>
      <p className="mt-2 justify-center text-center text-sm font-semibold">
        ESTE DOCUMENTO ES EL ÚNICO COMPROBANTE VALIDO QUE LE ACREDITA COMO DUEÑO
        DEL EQUIPO POR ENDE EL ORIGINAL DE ESTE DOCUMENTO ES DE PRESENTACIÓN
        OBLIGATORIA PARA SU RETIRO
      </p>
    </div>
  );
}

export function Description2() {
  return (
    <div>
      <p className="text-justify text-sm">
        El servicio técnico realizado tiene una garantía de treinta días sobre
        la falla reportada, insumos y materiales tienen garantía especificada
        por el fabricante.
      </p>

      <p className="mt-2 justify-center text-center text-sm font-semibold">
        ESTE DOCUMENTO ES EL ÚNICO COMPROBANTE VALIDO PARA VALIDAR GARANTÍA POR
        ENDE EL ORIGINAL DE ESTE DOCUMENTO ES DE PRESENTACIÓN OBLIGATORIA PARA
        LA GESTIÓN DE LA MISMA.
      </p>
    </div>
  );
}
