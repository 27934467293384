import * as React from "react";
import "./App.css";

import { AppContextProvider } from "./context/AppContext";
import RootRouter from "./RootRouter";

export default function App() {
  return (
    <AppContextProvider>
      <RootRouter></RootRouter>
    </AppContextProvider>
  );
}
