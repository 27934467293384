import React from 'react';
import { useNavigate } from 'react-router-dom';
import { postRecoverPassword } from '../../services/ws';
import Loading from '../../components/Loading';

import Swal from 'sweetalert2';

import logo from '../../assets/images/logo.png';

export default function Recover() {
  let navigate = useNavigate();

  const [user, setUser] = React.useState({
    ci: '',
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const resp = await postRecoverPassword(user);

      if (resp.success) {
        Swal.fire({
          title: 'Envío de correo',
          text: 'Se ha enviado un correo a tu cuenta',
          icon: 'success',
        });
        navigate('/');
      } else
        Swal.fire({
          title: 'Validación',
          text: resp.message,
          icon: 'info',
        });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex h-screen bg-gradient-to-t from-gray-300 to-blue-500">
      <div className="m-auto w-full max-w-lg bg-white py-16 px-20 shadow-xl">
        <div className="my-6">
          <img src={logo} alt="logo" className="w-full" />
        </div>

        <div className="mt-4 mb-8 text-center text-lg font-medium uppercase text-gray-500">
          Recuperar contraseña
        </div>

        <form onSubmit={handleSubmit}>
          <div>
            <label
              className="mb-1 block text-sm font-semibold text-gray-500"
              htmlFor="ci"
            >
              Ingresa su número de cédula
            </label>
            <input
              value={user.ci}
              onChange={handleChange}
              name="ci"
              type="text"
              className={`mb-4 w-full rounded-md border p-2 text-sm outline-none transition duration-150 ease-in-out`}
              placeholder="Ingrese su cédula"
              minLength="10"
              maxLength="10"
              required
            />
          </div>

          {/* explicacion */}
          <div className="mt-4 mb-8 text-center text-sm font-medium text-gray-500">
            Recibirás un correo con instrucciones para recuperar tu contraseña
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="submit"
              className="w-full rounded-md bg-blue-600 py-2 px-4 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-blue-700"
            >
              Enviar
            </button>
          </div>

          <div className="mt-4 flex justify-center">
            <button
              type="button"
              className="w-full rounded-md bg-gray-300 py-2 px-4 text-sm font-semibold text-gray-500 transition duration-150 ease-in-out hover:bg-gray-400"
              onClick={() => navigate('/')}
            >
              Volver
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
