import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  getService,
  getUsers,
  getUserByCI,
  postService,
  putService,
  getEquipmentBySerie,
  validateEquipmentService,
} from '../../../services/ws';

import Swal from 'sweetalert2';
import ContentHeader from '../../../components/ContentHeader';
import { ButtonCustomRef } from '../../../components/ButtonCustomRef';
import {
  ContainerInput,
  InputCustom,
  SelectCustom,
  TextAreaCustom,
} from '../../../components/FormElements';
import { lstTypeEquipments, lstBrands } from '../../../utils/LstData';
import { AddSquare } from 'iconoir-react';
import image from '../../../assets/images/150.png';
import Loading from '../../../components/Loading';

import { AppContext } from '../../../context/AppContext';

import {
  validateSpecialCharacters,
  validateCI,
} from '../../../utils/validations';

export default function ServiceForm() {
  const [appState] = React.useContext(AppContext);
  const { id } = useParams();

  const btn = React.useRef();
  const navigate = useNavigate();

  const [current, setCurrent] = React.useState({});
  const [lstUsers, setLstUsers] = React.useState([]);

  const [user, setUser] = React.useState({});
  const [equipment, setEquipment] = React.useState({});

  const [isLoading, setIsLoading] = React.useState(true);

  React.useEffect(() => {
    handleGet();
  }, []);

  React.useEffect(() => {
    getDataCurrent(id);
  }, [id]);

  const getDataCurrent = async (idCurrent) => {
    if (idCurrent) {
      const resp = await getService(idCurrent);
      if (resp.success) {
        const data = resp.data;
        data.ci = data.client.ci;
        data.serie = data.equipment.serie;
        data.delivery_date = getDateTimeLocal(data.delivery_date);

        setCurrent(resp.data);
        setUser(resp.data.client);
        setEquipment(resp.data.equipment);
      }
    }
    setIsLoading(false);
  };

  const handleGet = async () => {
    const resp = await getUsers();
    if (resp.success) {
      const dataFilter = resp.data.filter((item) => item.roles[0]?.id !== 3);
      setLstUsers(dataFilter);
    }
  };

  const handleChange = (e) => {
    setCurrent({ ...current, [e.target.name]: e.target.value });
  };

  const handleChangeUser = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleChangeEquipment = (e) => {
    setEquipment({ ...equipment, [e.target.name]: e.target.value });
  };

  const handleGetUser = async (e) => {
    const resp = await getUserByCI(e.target.value);
    if (resp.success) {
      if (resp.data.id) {
        setUser(resp.data);
      }
    } else {
      setUser({});
    }
  };

  const handleGetEquipment = async (e) => {
    const resp = await getEquipmentBySerie({
      serie: e.target.value,
    });
    if (resp.success) {
      if (resp.data.id) {
        setEquipment(resp.data);
      }
    } else {
      setEquipment({});
    }
  };

  const handleChangeImage = (e) => {
    let file = e.target.files[0] || e.dataTransfer.files[0];
    if (!file) return;
    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      Swal.fire({
        title: 'Error',
        text: 'El archivo debe ser una imagen',
        icon: 'error',
      });
      return;
    }
    if (file.size > 5000000) {
      Swal.fire({
        title: 'Error',
        text: 'El archivo debe ser menor a 5MB',
        icon: 'error',
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = (er) => {
      setCurrent({ ...current, image: er.target.result });
    };
    reader.readAsDataURL(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    btn && btn.current.setAttribute('disabled', true);

    const isValidateCI = validateCI(current.ci);
    if (!isValidateCI.success) {
      Swal.fire({
        title: 'Cedula Incorrecta',
        text: isValidateCI.message,
        icon: 'warning',
      });
      btn && btn.current.removeAttribute('disabled');
      return;
    }

    if (!validateSpecialCharacters(user.name)) {
      Swal.fire({
        title: 'Nombre incorrecto',
        text: 'Solo se permiten letras, numeros y guion bajo',
        icon: 'warning',
      });
      btn && btn.current.removeAttribute('disabled');
      return;
    }

    if (!validateSpecialCharacters(user.last_name)) {
      Swal.fire({
        title: 'Apellido incorrecto',
        text: 'Solo se permiten letras, numeros y guion bajo',
        icon: 'warning',
      });
      btn && btn.current.removeAttribute('disabled');
      return;
    }

    try {
      const json = current;
      json.income_id = appState.user.id;
      json.client = user;
      json.equipment = equipment;

      if (id) {
        const resp = await putService(json);
        if (resp.success) {
          Swal.fire({
            icon: 'success',
            title: 'Se ha actualizado correctamente',
            text: '',
          });
        }
      } else {
        const validate = await validateEquipmentService({
          equipment_id: json.equipment.id,
        });

        if (!validate.success) {
          Swal.fire({
            icon: 'info',
            title: 'Validación',
            text: validate.message,
          });
          btn.current.removeAttribute('disabled');
          return;
        }

        const resp = await postService(json);
        if (resp.success) {
          Swal.fire({
            icon: 'success',
            title:
              'Se ha registrado correctamente, su número de orden es: ' +
              resp.data.id,
            text: '',
          });
        }
      }
      navigate('/services');
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }

    if (btn?.current) {
      btn.current.removeAttribute('disabled');
    }
  };

  const getDateTimeLocal = (dateString) => {
    const date = dateString ? new Date(dateString) : new Date();
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const month =
      date.getMonth() + 1 < 10
        ? `0${date.getMonth() + 1}`
        : date.getMonth() + 1;
    const year =
      date.getFullYear() < 10 ? `0${date.getFullYear()}` : date.getFullYear();
    const hour = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
    const minutes =
      date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();

    return `${year}-${month}-${day}T${hour}:${minutes}`;
  };

  const generateSerial = () => {
    let serial = '';
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    for (let i = 0; i < 6; i++) {
      serial += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    setCurrent({ ...current, serie: serial });
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex flex-col">
      <ContentHeader title={id ? 'Editar Servicio' : 'Registrar Servicio'}>
        {/* <ButtonCustomRef
          text="Guardar"
          ref={btn}
          form="form-current"
          type="submit"
        /> */}
      </ContentHeader>
      <div className="flex justify-center py-8">
        <form
          id="form-current"
          className="w-full max-w-3xl rounded-lg border border-blue-200 px-8 py-10"
          onSubmit={handleSubmit}
        >
          <div className="flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">
              Foto de evidencia
            </h2>
          </div>

          <div className="flex justify-center">
            <img
              className="w-44 rounded-xl"
              src={current.image ? current.image : image}
              alt="Imagen"
            />
          </div>

          <ContainerInput>
            <InputCustom
              text="Foto"
              name="image"
              onChange={handleChangeImage}
              type="file"
            />
          </ContainerInput>

          <div className="flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">Cliente</h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Cédula"
              name="ci"
              value={current.ci}
              n="2"
              onChange={handleChange}
              onKeyUp={handleGetUser}
              minLength="10"
              maxLength="10"
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Nombre"
              name="name"
              value={user.name}
              n="2"
              onChange={handleChangeUser}
              required
            />
            <InputCustom
              text="Apellido"
              name="last_name"
              value={user.last_name}
              n="2"
              onChange={handleChangeUser}
              required
            />
          </ContainerInput>
          <ContainerInput>
            <InputCustom
              text="Teléfono"
              name="phone"
              value={user.phone}
              n="2"
              onChange={handleChangeUser}
            />
            <InputCustom
              text="Email"
              name="email"
              value={user.email}
              n="2"
              type="email"
              onChange={handleChangeUser}
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Dirección"
              name="address"
              value={user.address}
              onChange={handleChangeUser}
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">
              Datos del equipo
            </h2>
          </div>

          <ContainerInput>
            <InputCustom
              text="Serie"
              name="serie"
              value={current.serie}
              n="2"
              onChange={handleChange}
              onKeyUp={handleGetEquipment}
              rightContent={
                <button
                  className="flex items-center justify-center px-2 hover:border-none"
                  type="button"
                  onClick={generateSerial}
                >
                  <AddSquare />
                </button>
              }
              required
            />
            <SelectCustom
              text="Tipo"
              name="type"
              value={equipment.type}
              n="2"
              onChange={handleChangeEquipment}
              lst={lstTypeEquipments}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <SelectCustom
              text="Marca"
              name="brand"
              value={equipment.brand}
              n="2"
              onChange={handleChangeEquipment}
              lst={lstBrands}
              required
            />
            <InputCustom
              text="Modelo"
              name="model"
              value={equipment.model}
              n="2"
              onChange={handleChangeEquipment}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <TextAreaCustom
              text="Accesorios"
              name="accessories"
              value={current.accessories}
              onChange={handleChange}
            />
          </ContainerInput>

          <ContainerInput>
            <TextAreaCustom
              text="Falla reportada"
              name="failure"
              value={current.failure}
              onChange={handleChange}
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <h2 className="text-xl font-bold text-gray-700">Responsable</h2>
          </div>

          <ContainerInput>
            <SelectCustom
              text="Responsable"
              name="responsible_id"
              value={current.responsible_id}
              onChange={handleChange}
              lst={lstUsers}
              required
            />
          </ContainerInput>

          <ContainerInput>
            <InputCustom
              text="Fecha de entrega"
              name="delivery_date"
              type="datetime-local"
              value={
                current.delivery_date
                  ? current.delivery_date
                  : getDateTimeLocal()
              }
              onChange={handleChange}
              required
            />
          </ContainerInput>

          <div className="mt-8 flex flex-col p-3">
            <ButtonCustomRef
              text="Guardar"
              ref={btn}
              form="form-current"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
}
