import React from 'react';
import { GoogleMapFrame } from '../../components/GoogleMap';

import { direction } from '../../utils/infoData';

export default function WelcomeClient() {
  const src =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1996.5308902009665!2d-78.65187063193028!3d-1.6734510787486814!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91d3a82f318532c7%3A0x9f3e5fc2272d4677!2sComercial%20Machado!5e0!3m2!1ses!2sec!4v1653779780253!5m2!1ses!2sec';

  return (
    <div className="flex flex-col items-center">
      <h1 className="my-8 text-2xl font-semibold text-gray-800">
        Bienvenido a la plataforma del cliente
      </h1>
      <GoogleMapFrame src={src} title="Mapa" width="90%" height="600px" />
      <p className="mt-2 font-semibold text-blue-500">Encuentranos en:</p>
      <p className="mt-2 text-justify font-medium">{direction}</p>
      <p className="mt-2 text-sm">
        <b className="text-blue-500">Nota: </b>Si deseas actualizar tus datos o
        cambiar tu contraseña, puedes hacerlo en la sección de perfil
      </p>
    </div>
  );
}
