import React from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../context/AppContext';
import Loading from '../../components/Loading';

import Swal from 'sweetalert2';
import { login } from '../../services/ws';

import logo from '../../assets/images/logo.png';

export default function Login() {
  const [, setAppState] = React.useContext(AppContext);

  const [user, setUser] = React.useState({
    ci: '0123456789',
    password: '0123456789',
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const resp = await login(user);

      if (resp.success) {
        setAppState.login(resp.data, resp.token);
      } else
        Swal.fire({
          title: 'Validación',
          text: resp.message,
          icon: 'info',
        });
    } catch (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    }
    setIsLoading(false);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="flex h-screen bg-gradient-to-t from-gray-300 to-blue-500">
      <div className="m-auto w-full max-w-lg bg-white py-16 px-20 shadow-xl">
        <div className="my-6">
          <img src={logo} alt="logo" className="w-full" />
        </div>

        {/* <h1 className="mt-4 mb-8 text-center text-2xl font-medium uppercase">
          iniciar sesión
        </h1> */}

        <form onSubmit={handleSubmit}>
          <div>
            <label
              className="mb-1 block text-sm font-semibold text-gray-500"
              htmlFor="ci"
            >
              Cédula
            </label>
            <input
              value={user.ci}
              onChange={handleChange}
              name="ci"
              type="text"
              className={`mb-4 w-full rounded-md border p-2 text-sm outline-none transition duration-150 ease-in-out`}
              placeholder="Ingrese su cédula"
              minLength="10"
              maxLength="10"
              required
            />
          </div>
          <div>
            <label
              className="mb-1 block text-sm font-semibold text-gray-500"
              htmlFor="password"
            >
              Contraseña
            </label>
            <input
              value={user.password}
              onChange={handleChange}
              name="password"
              type="password"
              className={`mb-4 w-full rounded-md border p-2 text-sm outline-none transition duration-150 ease-in-out`}
              placeholder="Ingrese su contraseña"
              minLength={8}
              required
            />
          </div>

          {/* recover password */}
          {/* <div className="mt-4 flex items-center justify-between">
            <div className="flex items-center"></div>
            <div className="flex items-center">
              <Link
                to="/recover"
                className="text-sm font-semibold text-gray-500 transition duration-150 ease-in-out hover:text-blue-600"
              >
                Olvidé mi contraseña
              </Link>
            </div>
          </div> */}

          <div className="mt-4 flex justify-center">
            <button
              type="submit"
              className="w-full rounded-md bg-blue-600 py-2 px-4 text-sm font-semibold text-white transition duration-150 ease-in-out hover:bg-blue-700"
            >
              Iniciar sesión
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
