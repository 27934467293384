import React from 'react';
import { Link } from 'react-router-dom';
import { Home, User, LogOut, Box } from 'iconoir-react';
import { ButtonNav, ButtonNavLogout } from './ButtonNav';

import logo from '../assets/images/logo.png';
/* import logo2 from "../assets/images/logo2.png"; */
import { AppContext } from '../context/AppContext';

export default function Navbar({ closeSession }) {
  const [appState] = React.useContext(AppContext);

  const idRole = appState.user.roles[0]?.id;

  return (
    <div className="max-h-screen w-[260px] border bg-white py-8 px-4 shadow-xl">
      <div className="mb-6 border-b px-4 pb-6">
        <Link to="/" className="">
          <img src={logo} alt="logo" />
        </Link>
      </div>

      <div className="">
        <ButtonNav text="Panel Principal" to="/">
          <Home />
        </ButtonNav>

        {idRole === 1 && (
          <ButtonNav text="Usuarios" to="/users">
            <User />
          </ButtonNav>
        )}

        {idRole === 1 && (
          <ButtonNav text="Materiales e Insumos" to="/materials">
            <Box />
          </ButtonNav>
        )}

        {idRole === 1 && (
          <ButtonNav text="Equipos" to="/equipments">
            <Box />
          </ButtonNav>
        )}

        {(idRole === 1 || idRole === 2) && (
          <ButtonNav text="Servicios" to="/services">
            <Box />
          </ButtonNav>
        )}

        {idRole === 3 && (
          <ButtonNav text="Mis Servicios" to="/services/client">
            <Box />
          </ButtonNav>
        )}

        {idRole === 1 && (
          <ButtonNav text="Reporte de Servicios" to="/reports/services">
            <Box />
          </ButtonNav>
        )}
      </div>

      <div className="mx-3 mt-4 mb-3">
        <span className="text-xl font-bold text-blue-900">Opciones</span>
      </div>

      <ButtonNav text="Perfil" to={`/profile/${appState.user.id}`}>
        <User />
      </ButtonNav>
      <ButtonNavLogout text="Cerrar Sesión" onClick={closeSession}>
        <LogOut />
      </ButtonNavLogout>

      {/* <div className="mt-12 flex w-full items-center justify-center">
        <div className="w-28 border-b">
          <Link to="/" className="">
            <img src={logo2} alt="logo" />
          </Link>
        </div>
      </div> */}
    </div>
  );
}
